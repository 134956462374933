<template>
  <main class="message-row">
    <LandingHeader />

    <section class="top-screen">
      <div class="top-screen__left">
        <h1 class="top-screen__left__title">{{ $t("landing.main.top.title") }}</h1>
        <h3 class="top-screen__left__sub-title">
          {{ $t("landing.main.top.subtitle") }}
        </h3>
        <v-btn class="top-screen__left__btn landing-btn" color="primary" dark depressed @click="openContactDialog"
          >{{ $t("landing.requestDemo") }}
        </v-btn>
      </div>
      <img alt="" class="top-screen__right__img" src="@/assets/landing/main.png" />
    </section>

    <section class="solutions">
      <img alt="" class="solutions__logo-left" src="@/assets/landing/bg-logo-grey.png" />
      <img alt="" class="solutions__logo-right" src="@/assets/landing/bg-logo-grey.png" />
      <h1 class="solutions__title">{{ $t("landing.main.solutions.title") }}</h1>
      <h3 class="solutions__sub-title">
        {{ $t("landing.main.solutions.subtitle") }}
      </h3>

      <div class="solutions__tabs">
        <v-tabs class="solutions__tabs__panel" @change="onTabChange">
          <v-tab active-class="solutions__tabs__panel__item--active" class="solutions__tabs__panel__item"
            ><img alt="" class="solutions__tabs__panel__item__icon" src="@/assets/landing/main-patients.svg" />{{
              $t("landing.pages.patients")
            }}
          </v-tab>
          <v-tab active-class="solutions__tabs__panel__item--active" class="solutions__tabs__panel__item"
            ><img alt="" class="solutions__tabs__panel__item__icon" src="@/assets/landing/patients-employer.svg" />{{
              $t("landing.pages.employers")
            }}
          </v-tab>
          <v-tab active-class="solutions__tabs__panel__item--active" class="solutions__tabs__panel__item"
            ><img alt="" class="solutions__tabs__panel__item__icon" src="@/assets/landing/main-brokers.svg" />{{
              $t("landing.pages.brokers")
            }}
          </v-tab>
        </v-tabs>

        <div v-if="tab === 0" class="solutions__tabs__content">
          <p class="solutions__tabs__content__text">
            {{ $t("landing.main.solutions.tabs.patients.text1") }}
          </p>
          <p class="solutions__tabs__content__text">
            {{ $t("landing.main.solutions.tabs.patients.text2") }}
          </p>
          <div class="solutions__tabs__content__learn">
            <router-link class="solutions__tabs__content__learn__text" to="/patients">
              {{ $t("landing.main.solutions.learn") }}
            </router-link>
            <img alt="" class="solutions__tabs__content__learn__icon" src="@/assets/landing/learn_arrow.svg" />
          </div>
        </div>

        <div v-if="tab === 1" class="solutions__tabs__content">
          <p class="solutions__tabs__content__text">
            {{ $t("landing.main.solutions.tabs.employers.text1") }}
          </p>
          <p class="solutions__tabs__content__text">
            {{ $t("landing.main.solutions.tabs.employers.text2") }}
          </p>
          <p class="solutions__tabs__content__text">
            {{ $t("landing.main.solutions.tabs.employers.text3") }}
          </p>
          <p class="solutions__tabs__content__text">
            {{ $t("landing.main.solutions.tabs.employers.text4") }}
          </p>
          <div class="solutions__tabs__content__learn">
            <router-link class="solutions__tabs__content__learn__text" to="/employers">
              {{ $t("landing.main.solutions.learn") }}
            </router-link>
            <img alt="" class="solutions__tabs__content__learn__icon" src="@/assets/landing/learn_arrow.svg" />
          </div>
        </div>

        <div v-if="tab === 2" class="solutions__tabs__content">
          <p class="solutions__tabs__content__text">
            {{ $t("landing.main.solutions.tabs.brokers.text1") }}
          </p>
          <p class="solutions__tabs__content__text">
            {{ $t("landing.main.solutions.tabs.brokers.text2") }}
          </p>
          <p class="solutions__tabs__content__text">
            {{ $t("landing.main.solutions.tabs.brokers.text3") }}
          </p>
          <div class="solutions__tabs__content__learn">
            <router-link class="solutions__tabs__content__learn__text" to="/brokers">
              {{ $t("landing.main.solutions.learn") }}
            </router-link>
            <img alt="" class="solutions__tabs__content__learn__icon" src="@/assets/landing/learn_arrow.svg" />
          </div>
        </div>
      </div>
    </section>

    <section class="platform">
      <div class="platform__info-block">
        <h1 class="platform__info-block__title">{{ $t("landing.main.platform.title") }}</h1>
        <p class="platform__info-block__text">
          {{ $t("landing.main.platform.text1") }}
        </p>
        <p class="platform__info-block__text">
          {{ $t("landing.main.platform.text2") }}
        </p>
        <v-btn class="platform__info-block__btn landing-btn" color="primary" dark depressed @click="openContactDialog"
          >{{ $t("landing.requestDemo") }}
        </v-btn>
      </div>
      <img alt="" class="platform__img" src="@/assets/landing/platform.webp" />
    </section>

    <section class="virtual-care">
      <h1 class="virtual-care__title">
        {{ $t("landing.main.virtualCare.title") }}
      </h1>
      <h3 class="virtual-care__sub-title">
        {{ $t("landing.main.virtualCare.subtitle") }}
      </h3>
      <div class="virtual-care__feats">
        <div class="virtual-care__feats__item">
          <img alt="" class="virtual-care__feats__item__img" src="@/assets/landing/feel-better.svg" />
          <p class="virtual-care__feats__item__title">{{ $t("landing.main.virtualCare.feats.feelBetter.title") }}</p>
          <p class="virtual-care__feats__item__text">
            {{ $t("landing.main.virtualCare.feats.feelBetter.text") }}
          </p>
        </div>
        <div class="virtual-care__feats__item">
          <img alt="" class="virtual-care__feats__item__img" src="@/assets/landing/easy-access.svg" />
          <p class="virtual-care__feats__item__title">{{ $t("landing.main.virtualCare.feats.easyAccess.title") }}</p>
          <p class="virtual-care__feats__item__text">
            {{ $t("landing.main.virtualCare.feats.easyAccess.text") }}
          </p>
        </div>
        <div class="virtual-care__feats__item">
          <img alt="" class="virtual-care__feats__item__img" src="@/assets/landing/architecture.svg" />
          <p class="virtual-care__feats__item__title">
            {{ $t("landing.main.virtualCare.feats.patientCentric.title") }}
          </p>
          <p class="virtual-care__feats__item__text">
            {{ $t("landing.main.virtualCare.feats.patientCentric.text") }}
          </p>
        </div>
        <div class="virtual-care__feats__item">
          <img alt="" class="virtual-care__feats__item__img" src="@/assets/landing/visit-center.svg" />
          <p class="virtual-care__feats__item__title">{{ $t("landing.main.virtualCare.feats.visitCenter.title") }}</p>
          <p class="virtual-care__feats__item__text">{{ $t("landing.main.virtualCare.feats.visitCenter.text") }}</p>
        </div>
        <div class="virtual-care__feats__item">
          <img alt="" class="virtual-care__feats__item__img" src="@/assets/landing/medical-info.svg" />
          <p class="virtual-care__feats__item__title">
            {{ $t("landing.main.virtualCare.feats.medicalInformation.title") }}
          </p>
          <p class="virtual-care__feats__item__text">
            {{ $t("landing.main.virtualCare.feats.medicalInformation.text") }}
          </p>
        </div>
      </div>
    </section>

    <section class="treat">
      <div class="treat__up">
        <div class="treat__up__text-block">
          <h1 class="treat__title">{{ $t("landing.main.treat.title") }}</h1>
          <p class="treat__text">
            {{ $t("landing.main.treat.text") }}
          </p>
        </div>
        <div class="treat__up__controls">
          <div class="treat__up__controls__left" @click="previousTreatSlide"></div>
          <div class="treat__up__controls__right" @click="nextTreatSlide"></div>
        </div>
      </div>
      <div class="treat__carousel-wrap">
        <Flickity ref="flickityTreat1" :options="flickityTreatOptions" class="treat__carousel-wrap__carousel">
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/covid.svg" />
            <div class="treat__carousel__item__right">
              <p class="treat__carousel__item__text">COVID-19</p>
              <p class="treat__carousel__item__sub-text">{{ $t("landing.main.treat.carousel.covid") }}</p>
            </div>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/flu.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.flu") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/cold.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.commonCold") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/asthma.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.asthma") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/cough.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.cough") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/sinus.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.sinus") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/throat.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.throat") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/ear.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.ear") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/pinkeye.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.pinkeye") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/coldsores.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.coldSores") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/allergies.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.allergies") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/rashes.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.rashes") }}</p>
          </div>
        </Flickity>
        <Flickity ref="flickityTreat2" :options="flickityTreatOptions" class="treat__carousel-wrap__carousel">
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/acne.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.acne") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/sprains.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.sprains") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/nausea.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.nausea") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/headache.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.headache") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/urinary.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.urinary") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/yeast.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.yeast") }}</p>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/vaginosis.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.vaginosis") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/sexual.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.std") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/meds.svg" />
            <div class="treat__carousel__item__right">
              <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.meds.title") }}</p>
              <p class="treat__carousel__item__sub-text">{{ $t("landing.main.treat.carousel.meds.text") }}</p>
            </div>
          </div>

          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/advise.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.advise") }}</p>
          </div>
          <div class="treat__carousel__item">
            <img alt="" class="treat__carousel__item__img" src="@/assets/landing/review.svg" />
            <p class="treat__carousel__item__text">{{ $t("landing.main.treat.carousel.review") }}</p>
          </div>
        </Flickity>
      </div>
    </section>

    <section class="services">
      <div class="services__wrap">
        <div class="services__left">
          <h1 class="services__left__title">
            {{ $t("landing.main.services.title") }}
          </h1>
          <p class="services__right__text">
            {{ $t("landing.main.services.text") }}
          </p>
          <v-btn class="services__right__btn landing-btn" color="primary" dark depressed @click="openContactDialog"
            >{{ $t("landing.main.services.learnMore") }}
          </v-btn>
        </div>

        <div class="services__right">
          <img alt="" class="services__right__img" src="@/assets/landing/smiling-guy.jpg" />

          <div class="services__left__feat">
            <img alt="" class="services__left__feat__img" src="@/assets/landing/bilingual.svg" />
            <p class="services__left__feat__text">{{ $t("landing.main.services.feats.bilingual") }}</p>
          </div>
          <div class="services__left__feat">
            <img alt="" class="services__left__feat__img" src="@/assets/landing/quality.svg" />
            <p class="services__left__feat__text">{{ $t("landing.main.services.feats.certified") }}</p>
          </div>
          <div class="services__left__feat">
            <img alt="" class="services__left__feat__img" src="@/assets/landing/patient-exp.svg" />
            <p class="services__left__feat__text">{{ $t("landing.main.services.feats.satisfaction") }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="feedback">
      <div class="feedback__top">
        <div class="feedback__top__left">
          <img alt="" class="feedback__top__left__img" src="@/assets/landing/heart.svg" />
          <h1 class="feedback__top__left__title">{{ $t("landing.main.feedback.title") }}</h1>
        </div>
        <div class="feedback__top__right">
          <div class="feedback__top__right__left-arrow" @click="previousFeedbackSlide"></div>
          <div class="feedback__top__right__right-arrow" @click="nextFeedbackSlide"></div>
        </div>
      </div>
      <Flickity ref="flickityFeedback" :options="flickityFeedbackOptions" class="feedback__carousel">
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar1.png" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Brian</p>
              <p class="feedback__slide__personal-info__location">New Orleans LA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.9</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I have never experienced such a personalized experienced with a doctor, he listened and suggested to
              follow up with me in a few days for a bad cold. Amazing service."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar2.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Kyle</p>
              <p class="feedback__slide__personal-info__location">Long Beach CA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.7</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I was out of my blood pressure medications and due to COVID19 and work I was having a hard time making it
              to my PCP, Axis Clinic was able to verify and send a refill of my medication in just a few hours."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar3.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Tyler</p>
              <p class="feedback__slide__personal-info__location">San Jose CA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">5.0</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I never thought that speaking to a physician for a medical question could be so simple, I love this
              service."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar4.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Lindsey</p>
              <p class="feedback__slide__personal-info__location">Norfolk VA</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">5.0</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "Having this service for my young children has saved us money, time and worrisome nights! I am truly
              grateful to have this service."
            </p>
          </div>
        </div>

        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar5.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Stephanie</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.7</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "Axis.Clinic I can not recommend them enough, great service, they take the time to listen and to explain
              what they are thinking, you speak with the same doctor everytime, really makes you feel better the moment
              you talk to them"
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar6.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Mayra</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.8</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I was worried about missing work since we have a major project due in just 2 days, I was able to talk to
              my doctor the same day and that night I had my medications for a urinary tract infection"
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar7.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Katlyn</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">5.0</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "Taking our kids to the doctor's office is always a challenge since my husband and I both work long hours,
              I am so grateful for what they do!, it's a huge relief knowing we have Axis.Clinic to take care of us when
              we need it."
            </p>
          </div>
        </div>
        <div class="feedback__slide--wrap">
          <div class="feedback__slide">
            <img alt="" class="feedback__slide__img" src="@/assets/landing/avatar8.webp" />
            <div class="feedback__slide__personal-info">
              <p class="feedback__slide__personal-info__name">Fernando</p>
              <p class="feedback__slide__personal-info__location">Memphis TN</p>
            </div>
            <div class="feedback__slide__rating">
              <span class="feedback__slide__rating__number">4.9</span>
              <img alt="" class="feedback__slide__rating__star" src="@/assets/landing/star.svg" />
            </div>
            <p class="feedback__slide__text">
              "I am a high risk patients for COVID19 due to my history of ulcerative colitis, I am very worried about
              visiting the ER during the covid pandemic, thanks to Axis.Clinic I have gotten the care I need without
              putting myself at risk."
            </p>
          </div>
        </div>
      </Flickity>
    </section>

    <section class="request">
      <img alt="" class="request__img" src="@/assets/landing/smiling-girl.jpg" />
      <div class="request__text-block">
        <h1 class="request__text-block__title">{{ $t("landing.main.request") }}</h1>
        <v-btn class="request__text-block__btn landing-btn" color="primary" dark depressed @click="openContactDialog"
          >{{ $t("landing.requestQuote") }}
        </v-btn>
      </div>
    </section>

    <LandingFooter />

    <ContactUsDialog v-if="contactDialog" @close="closeContactDialog" />

    <img v-if="showArrowUp" alt="" class="arrow-up" src="@/assets/landing/arrow-up.svg" @click="scrollToTop" />
  </main>
</template>

<script>
import Flickity from "vue-flickity";

import ContactUsDialog from "./ContactUsDialog";
import LandingFooter from "./LandingFooter.vue";
import LandingHeader from "./LandingHeader.vue";

export default {
  name: "Landing",
  components: {
    LandingHeader,
    LandingFooter,
    Flickity,
    ContactUsDialog,
  },
  data() {
    return {
      tab: 0,
      flickityTreatOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
      },
      flickityFeedbackOptions: {
        prevNextButtons: false,
        wrapAround: true,
      },
      contactDialog: false,
      showArrowUp: false,
    };
  },
  methods: {
    nextTreatSlide() {
      this.$refs.flickityTreat1.next();
      this.$refs.flickityTreat2.next();
    },
    previousTreatSlide() {
      this.$refs.flickityTreat1.previous();
      this.$refs.flickityTreat2.previous();
    },
    nextFeedbackSlide() {
      this.$refs.flickityFeedback.next();
    },
    previousFeedbackSlide() {
      this.$refs.flickityFeedback.previous();
    },
    changeTab(tab) {
      this.tab = tab;
    },
    isActive(tab) {
      return this.tab === tab ? "active" : "";
    },
    onTabChange(value) {
      this.tab = value;
    },
    openContactDialog() {
      this.contactDialog = true;
    },
    closeContactDialog() {
      this.contactDialog = false;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "auto" });
    },
    onScroll() {
      this.showArrowUp = window.scrollY > 300;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "auto" });
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.message-row {
  * {
    font-size: 18px;
  }

  .arrow-up {
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0.5;
    z-index: 9;

    &:hover {
      opacity: 1;
    }
  }

  .v-slide-group__prev--disabled {
    display: none !important;
  }

  .flickity-page-dots {
    text-align: left;
    padding-left: 0 !important;

    @media (max-width: 480px) {
      margin-left: 40px;
    }
  }

  .flickity-page-dots .dot {
    width: 15px;
    height: 15px;
    transition: 0.3s ease;
  }

  .flickity-page-dots .dot.is-selected {
    background: $primary;
    width: 30px;
    border-radius: 15px;
  }

  .top-screen {
    display: flex;
    justify-content: space-between;
    padding: 100px 40px;
    position: relative;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 60px 20px 20px;
      flex-direction: column;
    }

    &__left {
      position: relative;
      z-index: 2;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__title {
        font-size: 52px;
        max-width: 666px;

        @media (max-width: 1180px) {
          max-width: 580px;
          font-size: 48px;
        }
        @media (max-width: 1024px) {
          font-size: 40px;
        }
        @media (max-width: 768px) {
          font-size: 32px;
          text-align: center;
          line-height: 40px;
        }

        strong {
          font-size: 52px;

          @media (max-width: 1024px) {
            font-size: 40px;
          }
          @media (max-width: 768px) {
            font-size: 32px;
          }
        }
      }

      &__sub-title {
        font-weight: normal;
        max-width: 666px;
        margin-top: 20px;

        @media (max-width: 1365px) {
          max-width: 50%;
        }
        @media (max-width: 768px) {
          text-align: center;
          margin: 20px auto 0;
          max-width: 100%;
        }
      }

      &__btn {
        margin: 40px auto 0;
      }
    }

    &__right {
      &__img {
        position: absolute;
        height: 850px;
        right: -150px;
        bottom: -190px;
        object-fit: contain;

        /*@media (max-width: 1600px) {
          right: -70px;
        }*/
        @media (max-width: 1366px) {
          right: -10%;
          height: 700px;
          bottom: -120px;
        }
        @media (max-width: 1024px) {
          height: 650px;
          right: -200px;
          bottom: -170px;
        }
        @media (max-width: 768px) {
          position: static;
          height: 100%;
          min-width: 620px;
          width: 100%;
          margin-bottom: -110px;
          margin-left: 50%;
          transform: translateX(-50%);
          z-index: 2;
        }
      }
    }
  }

  .solutions {
    background-color: var(--graybg);
    padding: 100px 0 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      padding: 60px 20px;
    }

    &__logo-left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 182px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &__logo-right {
      position: absolute;
      right: 40px;
      bottom: 10%;
      width: 132px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &__motto {
      text-transform: uppercase;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      &__img {
        width: 24px;
        margin-right: 5px;
      }
    }

    &__title {
      font-size: 52px;
      text-align: center;

      @media (max-width: 1024px) {
        font-size: 40px;
      }
      @media (max-width: 768px) {
        font-size: 28px;
      }
    }

    &__sub-title {
      font-weight: normal;
      text-align: center;
      margin: 20px 0 40px;
      max-width: 1200px;

      @media (max-width: 1440px) {
        max-width: 666px;
      }
    }

    &__tabs {
      position: relative;
      border-radius: 8px;
      max-width: 1200px;
      padding: 0 40px;
      margin-top: 100px;

      @media (max-width: 1440px) {
        max-width: 898px;
      }
      @media (max-width: 768px) {
        max-width: 100%;
        padding: 0 20px;
        margin-top: 0;
      }

      &__panel {
        margin-bottom: 30px;
        border-bottom: 1px solid #bccad5;

        & > div {
          height: fit-content;
          background-color: transparent !important;
        }

        .v-slide-group__content {
          display: flex;
          justify-content: space-between;
        }

        &__item {
          text-transform: initial;
          letter-spacing: initial;
          font-size: 24px;
          font-weight: bold;
          width: fit-content;
          height: 80px;
          opacity: 0.8;

          @media (max-width: 768px) {
            font-size: 18px;
            height: 60px;
            padding: 0;
          }
          @media (max-width: 580px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          &:hover {
            opacity: 1;
            color: $primaryblack2 !important;

            &:before {
              display: none;
            }
          }

          &__icon {
            width: 45px;
            margin-right: 10px;

            @media (max-width: 768px) {
              width: 30px;
            }
          }

          &--active {
            color: $primaryblack2;
            opacity: 1;
          }
        }
      }

      &__btn {
        border-radius: 10px;
        position: absolute;
        top: -30px;
        opacity: 0.5;

        @media (max-width: 768px) {
          padding: 20px 26px !important;
          font-size: 14px;
          top: -22px;
        }
        @media (max-width: 480px) {
          padding: 20px 14px !important;
        }

        &.active {
          opacity: 1;
        }

        &:nth-child(1) {
          left: 10%;

          @media (max-width: 768px) {
            left: 3%;
          }
          @media (max-width: 480px) {
            left: 0;
          }
        }

        &:nth-child(2) {
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(3) {
          right: 10%;
          @media (max-width: 768px) {
            right: 3%;
          }
          @media (max-width: 480px) {
            right: 0;
          }
        }
      }

      &__content {
        &__learn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 50px;
          width: fit-content;

          &__text {
            color: $primary;
            text-decoration: none;
            font-weight: bold;
            border-bottom: 1px solid transparent;

            &:hover {
              border-bottom: 1px solid $primary;
            }
          }

          &__icon {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .platform {
    display: flex;
    padding: 130px 0 50px;

    @media (max-width: 1024px) {
      padding: 100px 0;
    }
    @media (max-width: 768px) {
      padding: 60px 0;
      flex-direction: column;
    }

    &__info-block {
      padding: 0 40px;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__title {
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -0.83px;

        @media (max-width: 1024px) {
          font-size: 40px;
          line-height: 50px;
        }
        @media (max-width: 768px) {
          text-align: center;
          font-size: 28px;
          line-height: 1;
        }
      }

      &__text {
        margin-bottom: 40px;
      }
    }

    &__img {
      width: 50%;
      height: 100%;
      object-fit: contain;

      @media (max-width: 768px) {
        margin-top: 40px;
        width: 100%;
        height: auto;
      }
    }
  }

  .virtual-care {
    padding: 100px 20px;

    @media (max-width: 768px) {
      padding: 60px 20px;
    }

    &__title {
      font-weight: bold;
      font-size: 52px;
      line-height: 62px;
      letter-spacing: -0.83px;
      text-align: center;
      max-width: 900px;
      margin: 0 auto 20px;

      @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 50px;
      }
      @media (max-width: 768px) {
        font-size: 28px;
        line-height: 1;
      }
    }

    &__sub-title {
      text-align: center;
      font-weight: normal;
      max-width: 900px;
      margin: 0 auto;
    }

    &__feats {
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      justify-content: center;
      gap: 50px;
      padding: 0 150px;

      @media (max-width: 1440px) {
        padding: 0;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-basis: 340px;

        &__title {
          font-size: 24px;
          font-weight: bold;
          margin: 15px 0 10px;
          text-align: center;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }

        &__text {
          text-align: center;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }

        &__img {
          height: 50px;
        }
      }
    }
  }

  .treat {
    padding: 100px 0 250px;
    background-color: $primary;

    @media (max-width: 768px) {
      padding: 60px 0 100px;
    }

    &__carousel-wrap {
      &__carousel {
        & > .flickity-viewport {
          overflow: visible;
        }

        &:last-child {
          margin-top: 20px;
          transform: translateX(-150px);
        }
      }
    }

    &__up {
      display: flex;
      justify-content: space-between;
      padding: 0 40px;
      align-items: center;
      margin-bottom: 50px;

      @media (max-width: 480px) {
        flex-direction: column;
      }

      &__controls {
        display: flex;
        margin-left: 30px;
        align-self: flex-end;

        &__left {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid #fff;
          position: relative;
          margin-right: 20px;
          cursor: pointer;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:hover {
            background-color: #fff;

            &:before,
            &:after {
              background-color: $primary;
            }
          }
        }

        &__right {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid #fff;
          position: relative;
          margin-right: 20px;
          background-color: #fff;
          cursor: pointer;
          opacity: 0.7;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &__title {
      color: #fff;
      font-size: 52px;
      line-height: 62px;
      letter-spacing: -1px;
      max-width: 480px;

      @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 50px;
      }
      @media (max-width: 768px) {
        font-size: 28px;
        line-height: 1;
      }
    }

    &__text {
      color: #fff;
      max-width: 550px;
      margin-top: 20px;

      @media (max-width: 550px) {
        font-size: 14px;
      }
    }

    &__carousel {
      &__item {
        background-color: #fff;
        border-radius: 6px;
        width: 276px;
        height: 170px;
        margin-right: 20px;
        padding: 30px 22px;
        display: grid;
        grid-template-columns: 1fr 4fr;
        column-gap: 12px;

        @media (max-width: 768px) {
          width: 240px;
          height: 145px;
          padding: 20px 30px;
        }
        @media (max-width: 480px) {
          width: 170px;
          height: 120px;
          padding: 0 8px;
          justify-items: center;
          align-items: center;
          grid-template-columns: 1fr;
        }

        &__img {
          margin-bottom: 20px;
          height: 45px;

          @media (max-width: 480px) {
            height: 30px;
            margin-bottom: 10px;
            align-self: end;
          }
        }

        &__text {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 0;
          line-height: 29px;
          align-self: start;

          @media (max-width: 1024px) {
            font-size: 18px;
          }
          @media (max-width: 768px) {
            line-height: 24px;
          }
          @media (max-width: 480px) {
            font-size: 14px;
            text-align: center;
          }
        }

        &__sub-text {
          @media (max-width: 1024px) {
            font-size: 14px;
          }
          @media (max-width: 480px) {
            text-align: center;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .services {
    align-items: center;
    background-color: $primarylight1;
    position: relative;

    &__wrap {
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      justify-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 120px 40px;
      column-gap: 50px;

      @media (max-width: 768px) {
        column-gap: 30px;
        padding: 60px 40px 50px;
        grid-template-columns: 1fr;
        justify-items: initial;
      }
    }

    &__left {
      @media (max-width: 768px) {
        max-width: 100%;
      }

      &__title {
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -1px;
        margin-bottom: 50px;

        @media (max-width: 1024px) {
          font-size: 40px;
          line-height: 50px;
          margin-bottom: 30px;
        }
        @media (max-width: 768px) {
          font-size: 28px;
          line-height: 1;
        }
      }

      &__feat {
        display: flex;
        margin-bottom: 20px;
        align-items: center;

        &__img {
          margin-right: 20px;
        }

        &__text {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 0;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
    }

    &__right {
      max-width: 500px;
      position: relative;
      padding-top: 50px;
      align-self: flex-end;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 20px 0 60px;
      }

      &__img {
        position: absolute;
        bottom: 100%;
        width: 100%;
        border-radius: 10px;

        @media (max-width: 768px) {
          display: none;
        }
      }

      &__text {
        line-height: 32px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }

      &__author {
        font-size: 13px;
      }

      &__btn {
        margin-top: 40px;

        @media (max-width: 768px) {
          position: absolute;
          bottom: 50px;
        }
      }
    }
  }

  .feedback {
    padding: 100px 0 140px;
    position: relative;

    @media (max-width: 480px) {
      padding: 70px 0;
    }

    &__carousel {
      padding: 20px 40px;

      @media (max-width: 480px) {
        padding: 20px 0 70px;
      }
    }

    &__top {
      padding: 0 40px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
      }

      &__left {
        &__title {
          font-size: 52px;
          line-height: 62px;
          letter-spacing: -1px;
          margin-top: 35px;
          max-width: 580px;

          @media (max-width: 1150px) {
            font-size: 40px;
          }
          @media (max-width: 768px) {
            margin-bottom: 30px;
          }
          @media (max-width: 480px) {
            font-size: 28px;
            line-height: 1;
          }
        }

        &__img {
          @media (max-width: 1150px) {
            width: 60px;
            height: 60px;
          }
          @media (max-width: 480px) {
            width: 40px;
            height: 40px;
          }
        }
      }

      &__right {
        display: flex;
        z-index: 4;
        @media (max-width: 768px) {
          align-self: flex-end;
        }
        @media (max-width: 480px) {
          position: absolute;
          bottom: 80px;
        }

        &__left-arrow {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid $primary;
          position: relative;
          margin-right: 20px;
          cursor: pointer;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: $primary;
            transition: 0.2s ease;
          }

          &:hover {
            background-color: $primary;

            &:before,
            &:after {
              background-color: #fff;
            }
          }
        }

        &__right-arrow {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          border: 2px solid $primary;
          position: relative;
          margin-right: 20px;
          background-color: $primary;
          cursor: pointer;
          opacity: 0.8;
          transition: 0.2s ease;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            transform: translate(-50%) rotate(45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: calc(50% - 4px);
            transform: translate(-50%) rotate(-45deg);
            width: 10px;
            height: 2px;
            background-color: #fff;
            transition: 0.2s ease;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &__slide {
      width: 430px;
      max-height: 230px;
      display: grid;
      grid-template-columns: 50px 1fr 50px;
      grid-template-rows: 1fr 1fr;
      padding: 30px;
      box-shadow: 0px 2px 26px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin: 0 16px;
      align-items: center;
      row-gap: 16px;

      @media (max-width: 480px) {
        width: 360px;
      }

      &--wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 270px;
      }

      &__img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }

      &__personal-info {
        margin-left: 20px;

        &__name {
          margin-bottom: 0 !important;
          font-weight: bold;
        }

        &__location {
          margin-bottom: 0 !important;
          font-size: 14px;
        }
      }

      &__rating {
        display: flex;
        justify-content: space-between;
      }

      &__text {
        grid-column: 1 / end;
        font-size: 16px;

        @media (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }

  .request {
    display: flex;

    @media (max-width: 580px) {
      flex-direction: column;
    }

    &__img {
      width: 50%;
      @media (max-width: 580px) {
        width: 100%;
      }
    }

    &__text-block {
      padding: 0 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 50%;

      @media (max-width: 900px) {
        padding: 40px;
      }
      @media (max-width: 580px) {
        width: 100%;
        align-items: center;
      }

      &__title {
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -1px;
        margin-bottom: 50px;

        @media (max-width: 1150px) {
          font-size: 40px;
          line-height: 1;
        }
        @media (max-width: 768px) {
          font-size: 28px;
        }
        @media (max-width: 580px) {
          text-align: center;
          margin-bottom: 30px;
        }
        @media (max-width: 380px) {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
